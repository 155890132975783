import { CircularProgress, Modal } from '@mui/material';
import { useState } from 'react';
import SubmitButtonsField from '../Buttons/SubmitButtonsField';
import DummyModal from './DummyModal';
import { useEffect } from 'react';

const FilePreview = ({ item , url}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isSupported, setIsSupported] = useState(true); 

    useEffect(() => {
        const fileType = item?.mime_type;

        if (
            !fileType ||
            !(
                fileType === 'application/pdf' ||
                fileType.startsWith('image/') ||
                fileType === 'image/svg+xml' ||
                fileType === 'text/plain' ||
                fileType === 'application/octet-stream'
            )
        ) {
            setIsSupported(false);
            setLoading(false); 
        }
    }, [item?.mime_type]);

    const renderImage = () => (
        <img
            src={url}
            alt=''
            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }}
            onLoad={() => setLoading(false)}
            onError={() => {
                setError(true);
                setLoading(false);
            }}
        />
    );

    const renderIframe = () => (
        <iframe
            src={url}
            title={item.name}
            style={{ width: '100%', height: '100%', border: 'none' }}
            onLoad={() => setLoading(false)}
            onError={() => {
                setError(true);
                setLoading(false);
            }}
        />
    );

    const renderFile = () => {
        const fileType = item?.mime_type;

        if (error) {
            return <div>Error loading the file</div>;
        }

        if (!isSupported) {
            return <div>This preview is not available</div>;
        }

        switch (true) {
            case fileType === 'application/pdf':
                return renderIframe();
            case fileType.startsWith('image/') || fileType === 'image/svg+xml':
                return renderImage();
            case fileType === 'text/plain' || fileType === 'application/octet-stream':
                return renderIframe();
            default:
                return <div>This preview is not available</div>;
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                width: '100%',
                height: '600px',
            }}
        >
            {loading && (
                <CircularProgress
                    size={44}
                    sx={{ color: '#fb7a03', position: 'absolute', zIndex: 10 }}
                />
            )}
            <div
                style={{ width: '100%', height: '100%' }}
                className='d-flex align-items-center justify-content-center HeadlineOneBook'
            >
                {renderFile()}
            </div>
        </div>
    );
};

function PreviewModal({ modalIsOpen, toggleModal, item, url }) {
    const handleDownload = () => {
        const fileUrl = item.url;
        const fileName = item.name;

        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();
        link.remove();
        toggleModal();
    };

    return (
        <div>
            {modalIsOpen && (
                <Modal
                    onClose={toggleModal}
                    open={modalIsOpen}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <DummyModal
                        title='Preview'
                        onClose={toggleModal}
                        style={{ width: '800px', height: '700px' }}
                    >
                        <FilePreview item={item} url={url} />
                        <div className='VerticalSpacer bg-white p-3 rounded-bottom'>
                            <SubmitButtonsField child={true}>
                                <button
                                    id='Element'
                                    type='button'
                                    className='secondary'
                                    style={{ borderRadius: '50px', width: '125px' }}
                                    onClick={toggleModal}
                                >
                                    Cancel
                                </button>

                                <button
                                    type='button'
                                    className='d-flex align-items-center'
                                    style={{ borderRadius: '50px', width: '125px' }}
                                    id='Element'
                                    onClick={handleDownload}
                                >
                                    Download
                                </button>
                            </SubmitButtonsField>
                        </div>
                    </DummyModal>
                </Modal>
            )}
        </div>
    );
}

export default PreviewModal;
