/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { ReactComponent as ArrowUp } from '../../assets/icons/up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/down.svg';
import MyTaskCard from './MyTaskCard';
import SkeletonLoading from '../Others/SkeletonLoading';
import { detailsModal } from '../../utilities/utilities';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';


function ExpandableMyTasksView({
	days,
	weekends,
}) {
    const dispatch = useDispatch();
    const meetingTaskLoading = useSelector((state) => state.workSpaceReducer.meetingTaskLoading);
    const clientTaskLoading = useSelector((state) => state.workSpaceReducer.clientTaskLoading);
	const weeklyMeetingTasks = useSelector((state) => state.workSpaceReducer.weeklyMeetingTasks);
	const user = useSelector((state) => state.authReducer.user);
	const [expand, setExpand] = useState(true);

    const handleOnClick = useCallback(
		(task_id) => () => {
			dispatch(Actions.handleTaskClick({ taskId: task_id, modalType: detailsModal.MY_TASK }));
		},
		[],
	);

	const toggleExpand = () => {
		setExpand(!expand);
	};

	return (
		<>
			{days?.map((day, index) => {
				return (
					<div
						key={index}
						id={day.format()}
						className='d-flex flex-column justify-content-start align-items-start container'
						style={{
							width: `${100 / days.length}%`,
							height: 'auto',
							padding: '12px 12px 0px 12px',
						}}
					>
						{index === 0 ? (
							<div
								className='d-flex flex-column align-items-start w-100'
								style={{ cursor: 'pointer' }}
							>
								<div
									style={{ padding: '0px 6px 10px 6px' }}
									className='d-flex align-items-center text-left'
								>
									<h3 className='HeadlineThreeBook text-capitalize'>
										Meeting and My Tasks
									</h3>
								</div>
							</div>
						) : (index === 4 && !weekends) || (index === 6 && weekends) ? (
							<div
								className='d-flex w-100 justify-content-end'
								style={{ paddingRight: '8px', paddingBottom: '12px' }}
							>
								<div style={{ cursor: 'pointer' }} onClick={toggleExpand}>
									{expand ? <ArrowUp /> : <ArrowDown />}
								</div>
							</div>
						) : (
							<div className='' style={{ paddingBottom: '36px' }}></div>
						)}

						{expand && (
							<div className='w-100 d-flex justify-content-center'>
								<div
									style={{ gap: '24px', maxWidth: '250px' }}
									className='w-100 d-flex flex-column'
								>
									{weeklyMeetingTasks
										// ?.filter((task) => {
										// 	if (isFeatureFlag) return true;
										// 	else if (
										// 		user?.user_roles?.find((item) =>
										// 			item?.role_scopes
										// 				?.map((role) => role.team_id)
										// 				?.includes(task?.client?.team?.id),
										// 		)?.role_id === Member.teamMemberId
										// 	) {
										// 		return task.assignee_user_id !== null;
										// 	} else if (
										// 		user?.user_roles?.find((item) =>
										// 			item?.role_scopes
										// 				?.map((role) => role.team_id)
										// 				?.includes(task?.client?.team?.id),
										// 		)?.role_id === Member.externalCollaborator
										// 	) {
										// 		return task.assignee_user_id === user.userId;
										// 	} else {
										// 		return true;
										// 	}
										// })
										?.filter((t) =>
											t.task_extended_date === null
												? moment(t.task_start_date).format('MM-DD-YYYY') ===
												  moment(day).format('MM-DD-YYYY')
												: moment(t.task_extended_date).format(
														'MM-DD-YYYY',
												  ) === moment(day).format('MM-DD-YYYY'),
										)
										?.sort((a, b) => {
											const timeA = moment(a.task_start_time, 'hh:mm');
											const timeB = moment(b.task_start_time, 'hh:mm');

											return timeA.diff(timeB);
										})
										?.map((task, innerIndex) => {
											return (
												<SkeletonLoading
													key={innerIndex}
													loading={meetingTaskLoading || clientTaskLoading}
													variant='text'
													width={175}
													height={100}
												>
													<motion.div
														key={innerIndex}
														className={`h-100 w-100`}
														whileHover={{
															scale: 1.1,
															transition: { duration: 0.1 },
														}}
													>
														<MyTaskCard
															title={task?.task_title}
															taskId={task?.task_id}
															guests={task?.task_assignees}
															startTime={task?.task_start_time}
															endTime={task?.task_end_time}
															clientDba={task?.client_dba}
															companyName={task?.client_name}
															onTaskClick={handleOnClick}
															color={task?.task_color}
															inviteStatus={task?.task_status}
															creatorId={task?.my_task_creator_id}
															userId={user?.userId}
                                                            metadata={task?.metadata}
														/>
													</motion.div>
												</SkeletonLoading>
											);
										})}
								</div>
							</div>
						)}
					</div>
				);
			})}
		</>
	);
}
export default memo(ExpandableMyTasksView);
