/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailsModal, mapTasks, Member, TASK_STATUSES } from '../../utilities/utilities';
import { motion } from 'framer-motion';
import NewTaskCard from './NewTaskCard';
import { ReactComponent as ArrowUp } from '../../assets/icons/up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/down.svg';
import { useHistory, useLocation } from 'react-router';
import { routes } from '../../utilities/routes';
import SkeletonLoading from '../Others/SkeletonLoading';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';

function ExpandableClientTasksView({
	client,
	i,
	handleDragEnd,
	getTaskDetails,
	currentTaskDetails,
	handleDragOver,
	days,
	weekends,
	monthlyView,
	sideViewExpand,
}) {
	const dispatch = useDispatch();
	const [selectedClient, setSelectedClient] = useState(true);
	const user = useSelector((state) => state.authReducer.user);
	const history = useHistory();
	const location = useLocation();
	const meetingTaskLoading = useSelector((state) => state.workSpaceReducer.meetingTaskLoading);
	const clientTaskLoading = useSelector((state) => state.workSpaceReducer.clientTaskLoading);
	const [expand, setExpand] = useState('');

	const handleExpandableClient = () => {
		setSelectedClient(!selectedClient);
	};

	const handleOnClick = useCallback(
		(task_id) => () => {
			dispatch(Actions.handleTaskClick({ taskId: task_id, modalType: detailsModal.CLIENT }));
		},
		[],
	);

	const clientProfileNavigation = (client) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: 'Workspace',
		});
		if (from.length > 2) from.shift();
		history.push(`${routes['client-profile']}${client.client_id}`, from);
	};

	const client_all_tasks = client?.tasks;
	return (
		<>
			{days?.map((day, index) => {
				const todays_tasks = client_all_tasks
					.filter((item) => item != undefined)
					?.filter((task) => {
						if (
							user?.user_roles?.find((item) =>
								item?.role_scopes
									?.map((role) => role.team_id)
									?.includes(client?.team_id),
							)?.role_id === Member.teamMemberId
						) {
							return task.task_assignees?.length > 0;
						} else if (
							user?.user_roles?.find((item) =>
								item?.role_scopes
									?.map((role) => role.team_id)
									?.includes(client?.team_id),
							)?.role_id === Member.externalCollaborator
						) {
							return task?.task_assignees[0]?.user_profile?.id === user.userId;
						} else {
							return true;
						}
					})
					?.filter((t) =>
						t.extended_date === null
							? moment(t.start_date).format('MM-DD-YYYY') ===
							  moment(day).format('MM-DD-YYYY')
							: moment(t.extended_date).format('MM-DD-YYYY') ===
							  moment(day).format('MM-DD-YYYY'),
					);

				return (
					<div
						key={index}
						id={day.format()}
						onDragOver={handleDragOver && handleDragOver(day.format())}
						className='d-flex flex-column justify-content-start align-items-start container'
						style={{
							width: `${100 / days.length}%`,
							height: 'auto',
							padding: '12px 12px 0px 12px',
							background:
								currentTaskDetails &&
								!monthlyView &&
								day.diff(moment(currentTaskDetails?.start_date)) >= 0 &&
								day.diff(moment(currentTaskDetails?.due_date)) <= 0 &&
								currentTaskDetails?.client_id === client.client_id
									? '#A3E8D1'
									: '',
							borderRadius: '8px',
							// border: '2px solid red',
							// margin: '3px'
						}}
					>
						{index === 0 ? (
							<div
								style={{
									cursor: 'pointer',
									gap: '10px',
									display: 'flex',
									flexDirection: sideViewExpand ? 'row' : 'column',
									justifyContent: sideViewExpand ? 'start' : 'left',
									width: '100%',
									alignItems: 'start',
								}}
							>
								<SkeletonLoading
									loading={meetingTaskLoading || clientTaskLoading}
									variant='text'
									width={200}
									height={45}
								>
									<div onClick={() => clientProfileNavigation(client)}>
										<div
											style={{ padding: '0px 6px 10px 6px' }}
											className='d-flex align-items-center text-left'
										>
											<h3
												id={`client_${index}`}
												className='HeadlineThreeBook'
											>
												{client?.displayName
													? client?.displayName
													: client?.name}
											</h3>
										</div>
									</div>
								</SkeletonLoading>
								{sideViewExpand && (
									<div
										style={{ cursor: 'pointer' }}
										onClick={handleExpandableClient}
									>
										{selectedClient ? <ArrowUp /> : <ArrowDown />}
									</div>
								)}
							</div>
						) : (index === 4 && !weekends) || (index === 6 && weekends) ? (
							<div
								className='d-flex w-100 justify-content-end'
								style={{ paddingRight: '8px', paddingBottom: '12px' }}
							>
								<div style={{ cursor: 'pointer' }} onClick={handleExpandableClient}>
									{selectedClient ? <ArrowUp /> : <ArrowDown />}
								</div>
							</div>
						) : (
							<div className='' style={{ paddingBottom: '36px' }}></div>
						)}
						{selectedClient && (
							<div className='w-100 d-flex justify-content-center'>
								<div className='overlap-container'>
									{mapTasks(todays_tasks)?.map((tasksGroup, innerIndex) => {
										if (tasksGroup?.length > 0) {
											// const secondTask = tasks[innerIndex + 1];
											// const thirdTask = tasks[innerIndex + 2];

											const des_budgeted_hours_tasks = tasksGroup;
											// const des_budgeted_hours_tasks = new Array();
											// if (task) des_budgeted_hours_tasks[0] = task;
											// if (secondTask) des_budgeted_hours_tasks[1] = secondTask;
											// if (thirdTask) des_budgeted_hours_tasks[2] = thirdTask;

											const completed_tasks =
												des_budgeted_hours_tasks?.filter(
													(item) =>
														item.status === TASK_STATUSES.COMPLETED,
												);
											let not_completed_tasks =
												des_budgeted_hours_tasks?.filter(
													(item) =>
														item.status !== TASK_STATUSES.COMPLETED,
												);

											if (
												des_budgeted_hours_tasks?.filter(
													(i_item) =>
														i_item?.workflow_recurrence_id ==
														tasksGroup[0]?.workflow_recurrence_id,
												)?.length == des_budgeted_hours_tasks?.length
											) {
												not_completed_tasks =
													not_completed_tasks?.reverse();
											} else {
												not_completed_tasks = not_completed_tasks
													?.reverse()
													?.sort((a, b) => {
														const a_due_date = moment(
															a?.due_date,
														).format('MM-DD-YYYY');
														const b_due_date = moment(
															b?.due_date,
														).format('MM-DD-YYYY');
														return moment(a_due_date).isAfter(
															moment(b_due_date),
														)
															? -1
															: 0;
													});
											}
											const completed_filter_tasks = [
												...completed_tasks,
												...not_completed_tasks,
											];

											const height_category = [];
											des_budgeted_hours_tasks?.map((i_item) => {
												if (
													!height_category.includes(i_item.budgeted_hours)
												) {
													height_category.push(i_item.budgeted_hours);
												}
											});

											let task_max_height = Math.max(...height_category);
											task_max_height < 1.0
												? (task_max_height = 1)
												: task_max_height > 3.0
												? (task_max_height = 3)
												: (task_max_height = 2);

											return (
												<motion.div
													layout
													key={i + innerIndex + tasksGroup[0].id}
													onMouseEnter={() =>
														setExpand(i + innerIndex + tasksGroup[0].id)
													}
													onMouseLeave={() => setExpand('')}
													className={`overlap-tasks-container length-${task_max_height} ${
														expand ==
															i + innerIndex + tasksGroup[0].id &&
														'overlap-tasks-container-extended'
													} `}
												>
													{completed_filter_tasks?.map(
														(currentTask, currentIndex) => {
															const prevCompletedTasksLength =
																completed_filter_tasks
																	?.filter(
																		(item, j) =>
																			j < currentIndex,
																	)
																	?.filter(
																		(item) =>
																			item.status ==
																			TASK_STATUSES.COMPLETED,
																	)?.length;

															let budgeted_hours_height_category = 0;

															currentTask?.budgeted_hours < 1.0
																? (budgeted_hours_height_category = 3)
																: currentTask?.budgeted_hours > 3.0
																? (budgeted_hours_height_category = 1)
																: (budgeted_hours_height_category = 2);

															let total_tasks_of_workflow =
																client_all_tasks?.filter(
																	(item) =>
																		item?.workflow_recurrence_id !=
																			null &&
																		item?.workflow_recurrence_id ==
																			currentTask?.workflow_recurrence_id,
																);
															let current_task_index =
																total_tasks_of_workflow?.findIndex(
																	(item) =>
																		item.id == currentTask.id,
																);

															if (current_task_index < 0) {
																current_task_index = 0;
																total_tasks_of_workflow = [
																	currentTask,
																];
															}

															return (
																<SkeletonLoading
																	key={currentTask.id}
																	loading={
																		meetingTaskLoading ||
																		clientTaskLoading
																	}
																	variant='text'
																	width={175}
																	height={100}
																>
																	<motion.div
																		layout
																		key={currentTask.id}
																		id={currentTask.id}
																		className={`task-${budgeted_hours_height_category}-height task-${
																			currentIndex + 1
																		} task${
																			currentIndex + 1
																		}-step-forward-${prevCompletedTasksLength}`}
																		whileHover={{
																			zIndex: 1,
																			scale: 1.1,
																			transition: {
																				duration: 0.1,
																				delay: 0.6,
																			},
																		}}
																	>
																		<NewTaskCard
																			containerClassName={`task-${budgeted_hours_height_category}-height`}
																			// index={` ${des_budgeted_hours_tasks?.length - currentIndex}/${des_budgeted_hours_tasks?.length}`}
																			index={`${
																				current_task_index +
																				1
																			}/${
																				total_tasks_of_workflow?.length
																			}`}
																			taskHeight={
																				budgeted_hours_height_category
																			}
																			onDragEnd={handleDragEnd(
																				currentTask,
																				i,
																			)}
																			onDragStart={getTaskDetails(
																				currentTask,
																			)}
																			title={
																				currentTask.title
																			}
																			day={day}
																			taskDetails={
																				currentTask
																			}
																			budgeted_hours={
																				currentTask.budgeted_hours
																			}
																			onTaskClick={
																				handleOnClick
																			}
																			status={
																				currentTask.status
																			}
																			assignee_name={
																				currentTask
																					?.task_assignees[0]
																					?.user_profile
																					?.full_name
																			}
																			assignee_id={
																				currentTask
																					?.task_assignees[0]
																					?.user_profile
																					?.id
																			}
																			approvalFrom={
																				currentTask?.approval_from
																			}
																			image={
																				currentTask
																					?.task_assignees[0]
																					?.user_profile
																					?.image_url
																			}
																			taskId={currentTask?.id}
																			workflow_name={
																				currentTask
																					?.client_workflow
																					?.display_name ||
																				currentTask?.my_task
																					?.client_workflow
																					?.display_name
																			}
																			due_date={
																				currentTask?.due_date
																			}
																			color={
																				currentTask
																					?.client_workflow
																					?.color ||
																				currentTask?.my_task
																					?.workflow
																					?.color ||
																				'#fb7a03'
																			}
																			notes={
																				currentTask?.notes
																			}
																		/>
																	</motion.div>
																</SkeletonLoading>
															);
														},
													)}
												</motion.div>
											);
										}
									})}
								</div>
							</div>
						)}
					</div>
				);
			})}
		</>
	);
}

export default memo(ExpandableClientTasksView);
